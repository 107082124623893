// Main scripts
import '../node_modules/magnific-popup/dist/jquery.magnific-popup';
// Import just what we need

import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

import './js/popup-view';
import './js/menu';
import './js/slideshow';
import './js/quantity';
import './js/popup-cart';
import './js/mobile-nav';
