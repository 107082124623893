export default new class MobileNav {
    constructor () {
        this.nav = 'mobile-nav';
        this.toggler = 'toggler-nav';
        this.handler();
    }

    handler() {
        $(document).on('click', `.${this.toggler}`, ()=> {
            $.magnificPopup.open({
                items: {
                    src: `.${this.nav}`
                },
                type: 'inline',
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-slide-bottom'
            });
        })
    }
}();