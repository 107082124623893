import Swiper, { Navigation } from 'swiper';

export default new class Slideshow {
    constructor() {
        this.slideshowWrapper = 'slideshow__wrapper';
        
        this.navigation = {
            nextEl: '.slideshow__nav--next',
            prevEl: '.slideshow__nav--prev',
            disabledClass: 'slideshow__nav--disable'
        };
        this.handler();
    }

    handler() {
        document.addEventListener('DOMContentLoaded', ()=> {
            this.init();
        });
    }

    init() {
        const { navigation } = this;

        new Swiper(`.${this.slideshowWrapper}`, {
            modules: [Navigation],
            navigation,
            loop: false
        });
    }
}();