export default new class PopUpView {
    constructor() {
        this.productLayout = 'product-layout';
        this.selectorLink = '_js-popup-view';
        this.formPopupView ='_form-popup-view';
        this.popUpViewModal ='modal';
        this.modalHolder = 'modal-holder';
        this.dataProductId = 'data-product-id';
        this.pagePreloader = 'product-layout__preloader'
        this.pagePreloaderShow = 'product-layout__preloader--show'
        this.handler();
    }

    handler() {
        
        $(document).on('click', `.${this.selectorLink}`, (e)=> {

            const productId = $(e.currentTarget).attr(this.dataProductId);

            if(!productId) return;

            const thisProduct = $(e.currentTarget).closest(`.${this.productLayout}`);
            const preloader = thisProduct.find(`.${this.pagePreloader}`);

            $.ajax({
                type: 'post',
                dataType: 'html',
                url: 'index.php?route=extension/module/popup_view',
                data: 'product_id=' + productId,
                cache: false,
                beforeSend: ()=> {
                    preloader.addClass(this.pagePreloaderShow);
                },
                success: (data) => {
                    $(`.${this.modalHolder}`).html(data);

                    preloader.removeClass(this.pagePreloaderShow);

                    $.magnificPopup.open({
                        items: {
                            src: '.popup-view'
                        },
                        type: 'inline',
                        midClick: true,
                        removalDelay: 300,
                        mainClass: 'my-mfp-slide-bottom'
                    });
                }


            });
        });


        $(document).on('submit', '._form-popup-view', (e) => {
            e.preventDefault();

            console.log($(`.${this.formPopupView}`).serialize());

            $.ajax({
                url: 'index.php?route=checkout/cart/add',
                type: 'post',
                data: $(`.${this.formPopupView}`).serialize(),
                dataType: 'json',
                beforeSend: function() {

                },
                complete: function() {
                    $.magnificPopup.close();
                },
                success: function(json) {
                    if (json['success']) {

                        setTimeout(function () {
                            $('.cart a > span').html(json['total']);
                        }, 100);

                        $('.popup-cart__content').load('index.php?route=common/cart/info .product-cart__list');

                        setTimeout(() => {
                            $.magnificPopup.open({
                                items: {
                                    src: '.popup-cart'
                                },
                                type: 'inline',
                                midClick: true,
                                removalDelay: 300,
                                mainClass: 'my-mfp-slide-bottom'
                            });
                        }, 500);
                    }
                },

                error: function(xhr, ajaxOptions, thrownError) {
                    alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                }
            });
        });
    }
}