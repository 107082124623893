import { ajax } from "jquery";

export default new class PopupCart {
    constructor () {
        this.form = 'product-cart__form';
        this.btnClose = 'popup-cart__btn-close';
        this.fieldQuantity = 'product-cart__quantity';
        this.handler();
    }

    handler() {

        $(document).on('change', `.${this.fieldQuantity}`, ({currentTarget}) => {

            const form = $(currentTarget).parent();
            
            form.submit();
        });

        $(document).on('submit', `.${this.form}`, (e)=> {
            e.preventDefault();

            $.ajax({
                url: 'index.php?route=checkout/cart/edit',
                type: 'post',
                data: $(e.currentTarget).serialize(),
                dataType: 'json',
                beforeSend: function() {
                },
                complete: function() {
                },
                success: function(json) {
                    // Need to set timeout otherwise it wont update the total
                    setTimeout(function () {
                        $('.cart a > span').html(json['total']);
                    }, 100);

                    $('.popup-cart__content').load('index.php?route=common/cart/info .product-cart__list');
                },
                error: function(xhr, ajaxOptions, thrownError) {
                    alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                }
            });
            
            
        })

        $(document).on('click', `.${this.btnClose}`, (e)=> {
            $('.mfp-close').click();
        })
    }


}();