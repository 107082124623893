import Swiper, { FreeMode } from "swiper";

export default new class Menu {
    constructor() {
        this.menu = 'menu';
        this.menuLink = 'menu__link';
        this.menuFixed = 'menu--fixed';
        this.menuWrapper = 'menu__wrapper';
        this.handler();
        
    }

    handler() {
        new Swiper(`.${this.menuWrapper}`, {
            modules:[FreeMode],
            slidesPerView: 'auto',
            spaceBetween: 16,
            freeMode: true,
            freeModeMinimumVelocity: 0.2,
            freeModeMomentum: false,

            breakpoints: {
                768: {
                    spaceBetween: 24,
                }
            }
        });

        document.addEventListener('DOMContentLoaded', ()=> {

            const menu = $(`.${this.menuLink}`);

            if(menu) {
                this.stickyMenu();
                this.scrollTo();
            }
        });
    }
    
    stickyMenu() {

        const menu = $(`.${this.menu}`);
        const menuOffset = menu.offset().top;
        const menuHeight = menu.height();
        const sticky = menuOffset + menuHeight;

        window.addEventListener('scroll', ()=> {

            if($('html, body').scrollTop() > sticky) {
                menu.addClass(this.menuFixed);
                $('.page__main-inner').css({'paddingTop':`${menuHeight}` + 'px'});
            } else {
                menu.removeClass(this.menuFixed);
                $('.page__main-inner').css({'paddingTop': ''});
            }
        });
    }

    scrollTo() {

        $(document).on('click', `.${this.menuLink}`, (e)=> {

            e.preventDefault();
            
            const menuHeight = $(`.${this.menu}`).height();
            const url = $(e.currentTarget).attr('href');

            const offset = $(url).offset().top - menuHeight * 2;

            $('html, body').stop(true,true).animate({
                scrollTop: offset
            }, 'slow' );
        });
    }
}();