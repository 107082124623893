export default new class Quantity {

    constructor() {
        this.input = 'input__quantity-field';
        this.quantityBtn = 'input__quantity-btn';
        this.plus= 'input__quantity-btn--plus';
        this.minus = 'input__quantity-btn--minus';
        this.handler();
    }

    handler() {

        $(document).on('click', `.${this.quantityBtn}`, ({currentTarget}) => {
            
            const isPlus = $(currentTarget).hasClass(this.plus);
            const isMinus = $(currentTarget).hasClass(this.minus);
            const currentVal = $(`.${this.input}`);

            if(isPlus) {
                currentVal.val(parseInt(currentVal.val()) + 1);
            }

            if(isMinus && currentVal.val() > 1) {
                currentVal.val(parseInt(currentVal.val()) - 1);
            }
        });

    }

}();